import React, { useState } from "react";
import doctor from "../images/doctor.png";
import appointment from "../images/appointment.png";
import patient from "../images/patient.png";
import "./Dashboard.css";
import "../animations/animation.css";
import MonthlyPatientChart from "./companents/MonthlyPatientChart";

export default function Dashboard() {
  const [bookings, setBookings] = useState([
    {
      id: 1,
      name: "Jane Cooper",
      date: "14 Jun 2023",
      time: "10:00 AM",
      age: 52,
    },
    {
      id: 2,
      name: "John Doe",
      date: "15 Jun 2023",
      time: "11:00 AM",
      age: 34
    },
    {
      id: 3,
      name: "Emma Stone",
      date: "16 Jun 2023",
      time: "12:00 PM",
      age: 29,
    },
    {
      id: 4,
      name: "James Bond",
      date: "17 Jun 2023",
      time: "01:00 PM",
      age: 45
    },
    {
      id: 5,
      name: "Lucy Liu",
      date: "18 Jun 2023",
      time: "02:00 PM",
      age: 39
    },
    {
      id: 6,
      name: "Bruce Wayne",
      date: "19 Jun 2023",
      time: "03:00 PM",
      age: 40,
    },
    {
      id: 3,
      name: "Emma Stone",
      date: "16 Jun 2023",
      time: "12:00 PM",
      age: 29,
    },
    {
      id: 4,
      name: "James Bond",
      date: "17 Jun 2023",
      time: "01:00 PM",
      age: 45
    },
    {
      id: 5,
      name: "Lucy Liu",
      date: "18 Jun 2023",
      time: "02:00 PM",
      age: 39
    },
    {
      id: 6,
      name: "Bruce Wayne",
      date: "19 Jun 2023",
      time: "03:00 PM",
      age: 40,
    },
    {
      id: 3,
      name: "Emma Stone",
      date: "16 Jun 2023",
      time: "12:00 PM",
      age: 29,
    },
    {
      id: 4,
      name: "James Bond",
      date: "17 Jun 2023",
      time: "01:00 PM",
      age: 45
    },
    {
      id: 5,
      name: "Lucy Liu",
      date: "18 Jun 2023",
      time: "02:00 PM",
      age: 39
    },
    {
      id: 6,
      name: "Bruce Wayne",
      date: "19 Jun 2023",
      time: "03:00 PM",
      age: 40,
    },
  ]);


  return (
    <div className="main">
      <div className="cards-container">
        <div className="card scale-in-center">
          <i className="fa-solid fa-hospital-user" style={{ color: '#74C0FC', fontSize: '4em', margin: '4px' }}></i>
          <h3>Total Patients</h3>
          <h2>34</h2>
        </div>

        <div className="card scale-in-center">
          <i className="fa-solid fa-clock" style={{ color: '#74C0FC', fontSize: '4em', margin: '4px' }}></i>
          <h3>Total Appointments</h3>
          <h2>1231</h2>
        </div>
        <div className="card scale-in-center">
          <i className="fa-solid fa-user-nurse" style={{ color: '#74C0FC', fontSize: '4em', margin: '4px' }}></i>
          <h3>Total Doctors</h3>
          <h2>65</h2>
        </div>
      </div>

      {/* My Tailwind booking list */}

      <div className="NNBooking w-full px-3  flex flex-col md:flex-row" >
        <MonthlyPatientChart>

        </MonthlyPatientChart>
        <div className="">
          <div className="p-4 max-w-md bg-white rounded-lg border shadow-md sm:p-8 bg-gray-50 m-3">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold leading-none text-gray-900">
                Latest Customers
              </h3>
              <a
                href="/patients"
                className="text-sm font-medium text-blue-600 hover:underline"
              >
                View all
              </a>
            </div>
            <div>
              <div className="h-[400px] overflow-y-scroll">
                <ul role="list" className="divide-y divide-gray-200">
                  {bookings.map((booking) => (
                    <li className="py-3 sm:py-4" key={booking.id}>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full"
                            src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                            alt={booking.name}
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {booking.name}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {booking.date}
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900">
                          Age: {booking.age}
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900">
                          {booking.time} 🕑
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </div>
        </div>





      </div>


      <footer></footer>
    </div>
  );
}
