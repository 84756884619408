/*
Our Event Form Model for adding, editing, and deleting an event
AntD Components used
TimePicker - https://ant.design/components/time-picker
*/

import { useContext } from 'react'
import { AiOutlineAlignLeft, AiOutlineBook, AiOutlineCheck, AiOutlineClockCircle, AiOutlineClose, AiOutlineDelete, AiOutlineMenu } from 'react-icons/ai'
import GlobalContext from '../context/GlobalContext'
import { useState } from 'react'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'

// Color hexCodes
const hexCodes = {
    "indigo": "#3F00FF",
    "gray": "#808080",
    "green": "#00FF00",
    "blue": "#0000FF",
    "red": "#FF0000",
    "purple": "#800080",
};

const lableClasses = ["indigo", "gray", "green", "blue", "red", "purple"];
// Convert each label to its corresponding hex code using the map function
const labelHexCodes = lableClasses.map(label => hexCodes[label]);

const EventModal = () => {
    const { setShowEventModal, daySelected, dispatchCalEvent, selectedEvent, setSelectedEvent } = useContext(GlobalContext);
    const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
    const [description, setDescription] = useState(selectedEvent != null ? selectedEvent.description : "");
    const [selectedLabel, setSelectedLabel] = useState(
        selectedEvent != null ? labelHexCodes.find((lbl) => lbl === selectedEvent.label) :
            labelHexCodes[0]
    );

    const [selectedTime, setSelectedTime] = useState(selectedEvent ? selectedEvent.time : daySelected); // Initial state

    // Sets the time from the TimePicker
    const handleChange = (time) => {
        setSelectedTime(time);
    };

    // Handles form input and dispatches the event information into the context
    const handleSubmit = (e) => {
        e.preventDefault();
        const calenderEvent = {
            title,
            description,
            label: selectedLabel,
            day: daySelected.valueOf(),
            time: selectedTime,
            id: selectedEvent ? selectedEvent.id : Date.now()
        };
        if (selectedEvent) {
            dispatchCalEvent({ type: 'update', payload: calenderEvent });
        } else {
            dispatchCalEvent({ type: 'push', payload: calenderEvent });
        }
        setSelectedEvent("");
        setShowEventModal(false);
    };

    var date = new Date();
    date.setDate(date.getDate() - 1);

    // Allow adding events today and for future dates
    if (daySelected.valueOf() >= date.valueOf()) {
        return (
<div className='event fixed inset-0 flex justify-center items-center z-10 p-4 md:p-0'>
    <form className='bg-white rounded-lg shadow-2xl w-[calc(100%+50px)] md:w-[calc(50%+50px)] max-w-md max-h-[90vh] overflow-y-auto'>
        {/* Header Top Part Of the Event Model(form) */}
        <header className='bg-blue-100 px-4 py-2 flex justify-between items-center'>
            <span className='text-gray-400'>
                <AiOutlineMenu />
            </span>
            <div className='flex gap-2'>
                {selectedEvent && (
                    <span
                        className='text-gray-400 cursor-pointer'
                        onClick={() => {
                            dispatchCalEvent({
                                type: "delete", payload: selectedEvent
                            });
                            setSelectedEvent("");
                            setShowEventModal(false);
                        }}
                        title='Delete'
                    >
                        <AiOutlineDelete />
                    </span>
                )}
                <button title='Close' onClick={() => setShowEventModal(false)}>
                    <span className='text-gray-400'>
                        <AiOutlineClose />
                    </span>
                </button>
            </div>
        </header>
        {/* Content Part of our form */}
        <div className="p-3">
            <div className="grid grid-cols-1 gap-y-7">
                {/* First Input Title */}
                <input
                    type="text"
                    name="title"
                    placeholder='Add title'
                    required
                    className='pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-200'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                {/* Time Picker */}
                <div className='flex flex-col gap-3'>
                    <p>{daySelected.format("dddd, MMMM, DD")}</p>
                    <TimePicker
                        format="HH:mm"
                        minuteStep={15}
                        value={dayjs(selectedTime)}
                        onSelect={handleChange}
                        okButtonProps={{
                            className: 'my-custom-ok-button',
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
                {/* Description */}
                <input
                    type="text"
                    name="description"
                    placeholder='Add a description'
                    required
                    className='pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-200 flex items-center justify-center'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                {/* Labels */}
                <div className="flex gap-x-2">
                    {labelHexCodes.map((lblClass, i) => (
                        <div
                            key={i}
                            className="w-6 h-4 flex items-center justify-center cursor-pointer"
                            style={{ backgroundColor: `${lblClass}` }}
                            onClick={() => setSelectedLabel(lblClass)}
                        >
                            <span className='text-white text-xs'>
                                {selectedLabel === lblClass && <AiOutlineCheck />}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        {/* Footer with Save Button */}
        <footer className='flex justify-end md:w-full border-t px-7 py-5 mb-10 mt-5 p-10'>
            <button
                type='submit'
                className={`${ title && description ? "bg-blue-500 hover:bg-blue-600" : "bg-blue-300 cursor-not-allowed"} w-24 h-11 px-3 py-2 rounded text-white text-lg flex items-center justify-center`}
                disabled={!title || !description}
                onClick={(e) => handleSubmit(e)}>
                Save
            </button>
        </footer>
    </form>
</div>


        );
    } else {
        return null;
    }
};

export default EventModal;
