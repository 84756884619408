import React, { useState } from 'react';
import './Patients.css';

export default function Patients() {
  const generatePatients = () => {
    const samplePatients = [
      { name: 'Jane Cooper', gender: 'Female', imgSrc: 'https://via.placeholder.com/150', code: 'ID001' },
      { name: 'John Doe', gender: 'Male', imgSrc: 'https://via.placeholder.com/150', code: 'ID002' },
      { name: 'Emma Stone', gender: 'Female', imgSrc: 'https://via.placeholder.com/150', code: 'ID003' },
      { name: 'James Bond', gender: 'Male', imgSrc: 'https://via.placeholder.com/150', code: 'ID004' },
      { name: 'Lucy Liu', gender: 'Female', imgSrc: 'https://via.placeholder.com/150', code: 'ID005' },
      { name: 'Bruce Wayne', gender: 'Male', imgSrc: 'https://via.placeholder.com/150', code: 'ID006' }
    ];

    let patients = [];
    for (let i = 1; i <= 150; i++) {
      const patient = samplePatients[i % samplePatients.length];
      patients.push({
        id: i,
        name: `${patient.name} ${i}`,
        gender: patient.gender,
        imgSrc: patient.imgSrc,
        code: `${patient.code}${i}`,
        date: `Jun ${i % 30 + 1}, 2023`,
        time: `${i % 12 + 1}:00 ${i % 2 === 0 ? 'AM' : 'PM'}`
      });
    }
    return patients;
  };

  const [bookings] = useState(generatePatients());
  const [currentPage, setCurrentPage] = useState(1);
  const patientsPerPage = 50;

  const [selectedPatient, setSelectedPatient] = useState(null);

  const totalPages = Math.ceil(bookings.length / patientsPerPage);
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = bookings.slice(indexOfFirstPatient, indexOfLastPatient);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const viewMore = (patient) => {
    setSelectedPatient(patient);
  };

  const closeModal = () => {
    setSelectedPatient(null);
  };

  return (
    <div className='patientBody'>
      <div className='topnav'>
        <div className='search-container'>
          <form action='/action_page.php'>
            <input type='text' placeholder='Search..' name='search' />
            <button type='submit'>
              <i className='fa fa-search'></i>
            </button>
          </form>
        </div>
      </div>

      <div className='patientList'>
        {currentPatients.map((booking, index) => (
          <div key={booking.id} className='patientItem'>
            <div className='patientInfo'>
              <span className='patientSeq'>{indexOfFirstPatient + index + 1}</span>
              <img src={booking.imgSrc} alt={booking.name} className='patientImg' />
              <div className='patientDetails'>
                <strong>{booking.name}</strong>
                <p>ID: {booking.code}</p>
              </div>
              <p className='patientGender'>{booking.gender}</p>
              <p className='patientDateTime'>
                {booking.date} <br /> {booking.time}
              </p>
              <button onClick={() => viewMore(booking)} className='viewMoreBtn'>
                View More
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className='pagination'>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className='paginationBtn'
          disabled={currentPage === 1}
        >
          &lt;
        </button>

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`paginationBtn ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className='paginationBtn'
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>

      {selectedPatient && (
        <div className='modal fullscreen'>
          <div className='modalContent'>
            <span className='closeBtn' onClick={closeModal}>
              &times;
            </span>

            {/* Full screen layout with avatar on top */}
            <img src={selectedPatient.imgSrc} alt={selectedPatient.name} className='patientImgLarge' />

            <h2>{selectedPatient.name}</h2>
            <p><strong>ID:</strong> {selectedPatient.code}</p>
            <p><strong>Gender:</strong> {selectedPatient.gender}</p>
            <p><strong>Date:</strong> {selectedPatient.date}</p>
            <p><strong>Time:</strong> {selectedPatient.time}</p>
          </div>
        </div>
      )}
    </div>
  );
}
