import React from 'react';


export default function Payments() {
  

  return (
    <div>
      Payments
    </div>
  );
}
