import React, { useState } from 'react';
import './Settings.css';

const Settings = () => {
  const [profileDetails, setProfileDetails] = useState({
    city: '',
    website: '',
    bio: '',
  });

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const [profilePhoto, setProfilePhoto] = useState(null);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const saveProfileDetails = () => {
    // Save profile details logic
  };

  const savePassword = () => {
    // Save password logic
  };

  return (
    <div className="settings-container">
      <div className="section">
        <h2>Profile Details</h2>
        <div className="profile-photo">
          <input
            type="file"
            id="profilePhotoInput"
            accept="image/*"
            onChange={handleProfilePhotoChange}
          />
          <label htmlFor="profilePhotoInput">
            <img
              src={profilePhoto || 'https://www.shutterstock.com/image-vector/default-placeholder-doctor-halflength-portrait-600nw-1058724875.jpg'}
              alt="Profile"
            />
          </label>
        </div>
        <div className="input-group">
          <input
            type="text"
            name="city"
            placeholder="City"
            value={profileDetails.city}
            onChange={handleProfileChange}
          />
          <input
            type="text"
            name="website"
            placeholder="Website"
            value={profileDetails.website}
            onChange={handleProfileChange}
          />
        </div>
        <div className="input-group">
          <textarea
            name="bio"
            placeholder="Bio"
            maxLength="140"
            value={profileDetails.bio}
            onChange={handleProfileChange}
          ></textarea>
        </div>
        <p>Fields you're passionate about or would like to explore.</p>
        <div className="save-button">
          <button onClick={saveProfileDetails}>Save changes</button>
        </div>
      </div>

      <div className="section">
        <h2>Linked Accounts</h2>
        <div className="linked-accounts">
          <div className="account">
            <span>Google</span>
            <button>Unlink</button>
          </div>
          <div className="account">
            <span>Facebook</span>
            <button>Link Account</button>
          </div>
          <div className="account">
            <span>Twitter</span>
            <button>Link Account</button>
          </div>
          <div className="account">
            <span>LinkedIn</span>
            <button>Link Account</button>
          </div>
        </div>
      </div>

      <div className="section">
        <h2>Change Password</h2>
        <div className="password-change">
          <input
            type="password"
            name="oldPassword"
            placeholder="Old Password"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            name="repeatNewPassword"
            placeholder="Repeat New Password"
            value={passwords.repeatNewPassword}
            onChange={handlePasswordChange}
          />
        </div>
        <div className="save-button">
          <button onClick={savePassword}>Save changes</button>
        </div>
      </div>
    </div>
  );
};

export default Settings;